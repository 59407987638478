<template>
	<div>
		<div popupClose>
			<span>{{ closeMsg }}</span>
		</div>
		<div class="screen_box">
			<img :src="videoSrc" />
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		windowSize: [816, 518],
		videoSrc: '',
		countDown: 15,
		closeMsg: '',
		errorMsg: 'CCTV URL 정보가 올바르지 않습니다',
	}),
	mounted() {
		this.videoSrc = this.$route.query.videoSrc ? this.$route.query.videoSrc : '';

		if (this.videoSrc == '') {
			alert(this.errorMsg);
			window.close();
			return;
		}

		let content = $('.content');
		if (content) {
			content.css('width', '100%');
			content.css('padding-top', '0px');
		}

		let that = this;

		// fixed popup window size 800*450
		$(window).resize(function () {
			window.resizeTo(that.windowSize[0], that.windowSize[1]);
		});

		this.closeCount();
		setInterval(() => {
			this.closeCount();
		}, 1000); // 1초 마다 시간 변경
	},
	methods: {
		closeCount() {
			this.closeMsg = '이 화면은 ' + this.countDown + '초 후에 자동으로 닫힙니다';

			if (this.countDown-- === 0) window.close();
		},
	},
};
</script>

<style>
.screen_box {
	width: 100%;
	height: 450px;
	background-color: black;
}
.screen_box > img {
	max-width: 100%;
}
[popupClose] {
	position: absolute;
	top: 5px;
	left: 5px;
	width: 90%;
	font-size: 15px;
	color: white;
}
body {
	overflow: hidden;
}
</style>
